// # frozen_string_literal: true

import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
  static targets = ['region', 'ukField', 'usField', 'usInput', 'ukInput'];

  connect() {
    this.regionTarget.addEventListener('input', this.toggleFields.bind(this));
    this.toggleFields();
  }

  toggleFields() {
    if (this.regionTarget.value === 'US') {
      this.showUSFields();
      this.hideUKFields();
    } else {
      // Only two regions at the moment
      this.showUKFields();
      this.hideUSFields();
    }
  }

  // NOTE: We needed to full remove flex and flex-col for the turbo modal to render properly
  showUKFields() {
    this.ukFieldTargets.forEach((field) => {
      field.classList.remove('hidden');
      field.classList.add('flex', 'flex-col');
    });
  }

  hideUKFields() {
    this.ukFieldTargets.forEach((field) => {
      field.classList.add('hidden');
      field.classList.remove('flex', 'flex-col');
    });

    // Avoid saving pre entered values for different regions
    this.ukInputTargets.forEach((input) => {
      input.value = '';
    });
  }

  showUSFields() {
    this.usFieldTargets.forEach((field) => {
      field.classList.remove('hidden');
      field.classList.add('flex', 'flex-col');
    });
  }

  hideUSFields() {
    this.usFieldTargets.forEach((field) => {
      field.classList.add('hidden');
      field.classList.remove('flex', 'flex-col');
    });

    // Avoid saving pre entered values for different regions
    this.usInputTargets.forEach((input) => {
      input.value = '';
    });
  }
}
