import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['annotation', 'nameField', 'amountField'];

  static values = {
    dealId: String,
    invoiceId: String,
    selectedIndex: Number,
    type: String,
    isAdmin: Boolean,
  };

  connect() {
    // Reset from other line item validations.
    this.disableSaveButtons(false);
    // other init logic here...
    this.amountFieldTarget.addEventListener('input', this.validateCanSave.bind(this));
    this.nameFieldTarget.addEventListener('input', this.validateCanSave.bind(this));
    if (this.isAdminValue) {
      this.createBankIdListener();
      document.getElementById('bank-id').addEventListener('input', this.validateCanSave.bind(this));
    }
    this.validateCanSave();
  }

  // DOM way of doing TargetConnected
  createBankIdListener() {
    const bankIdElement = document.getElementById('bank-search-container');
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (_mutationList, _observer) => {
      // We really don't care which "event" is happening here, just that an event occurred to refire validateSave.
      this.validateCanSave();
    };

    const observer = new MutationObserver(callback);

    observer.observe(bankIdElement, config);
  }

  disableSaveButtons(disabled = true) {
    const buttons = document.querySelectorAll(
      '#submit-invoice-btn, #save-invoice-btn, #approve-invoice-btn'
    );
    buttons.forEach((button) => {
      button.disabled = disabled;
    });
  }

  amountFieldConnected(_event) {
    this.amountFieldTarget.addEventListener('input', this.validateCanSave.bind(this));
    this.validateCanSave();
  }

  nameFieldConnected(_event) {
    this.nameFieldTarget.addEventListener('input', this.validateCanSave.bind(this));
    this.validateCanSave();
  }

  validateBankingInfo() {
    // Bank ID is outside of Stimulus controller/target - grab via Dom selector
    const bankId = document.getElementById('bank-id');
    const hasBankingInfo = bankId.value.length > 0;

    if (hasBankingInfo) {
      return this.disableSaveButtons(false);
    } else {
      return this.disableSaveButtons(true);
    }
  }

  validateCanSave() {
    // ANY empty name or amount fields will disable the save buttons
    const nameFieldTargets = document.querySelectorAll('[data-other-line-item-target="nameField"]');
    const amountFieldTargets = document.querySelectorAll(
      '[data-other-line-item-target="amountField"]'
    );
    const hasEmptyNameFields = Array.from(nameFieldTargets).some(
      (nameField) => nameField.value === ''
    );
    const hasEmptyAmountFields = Array.from(amountFieldTargets).some(
      (amountField) => amountField.value === '' || amountField.value == 0
    );

    if (hasEmptyNameFields || hasEmptyAmountFields) {
      this.disableSaveButtons(true);
      return;
    }

    // call validateBankingInfo if isAdmin is true
    if (this.isAdminValue) {
      return this.validateBankingInfo();
    } else {
      this.disableSaveButtons(false);
      return;
    }
  }
}
