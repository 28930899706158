// # frozen_string_literal: true

import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
export default class extends Controller {
  static targets = ['option', 'contractGroupName', 'select'];

  connect() {
    this.initializeTomSelect();
    this.contractGroupNameTarget.addEventListener('input', this.validate.bind(this));
    this.validate();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.selectTarget) return;

    this.select = new TomSelect(this.selectTarget, {
      plugins: {
        remove_button: {},
        clear_button: {
          title: 'Remove all selected options',
          className: 'text-2xl clear-button',
        },
      },
      placeholder: 'Select...',
      hidePlaceholder: true,
      render: {
        option: function (data, escape) {
          return `<div data-default-allocation-percentage=${
            data.defaultAllocationPercentage
          } data-contracts-target="option"><span class="flex justify-between"><span> ${escape(
            data.text
          )} </span><span class='text-xs italic'>${escape(
            data.defaultAllocationPercentage
          )}%</span></span></div>`;
        },
        item: function (item, escape) {
          return `<div data-default-allocation-percentage=${
            item.defaultAllocationPercentage
          }  data-contracts-target="option"> ${escape(item.text)} </div>`;
        },
      },
    });

    this.select.on('item_add', this.validate.bind(this));
    this.select.on('item_remove', this.validate.bind(this));
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }

  enable() {
    this.select.enable();
  }

  validatePercentage() {}

  validate() {
    const name = this.contractGroupNameTarget.value;
    const errorMessage = document.getElementById('error-message');
    const btn = document.getElementById('save-contract-group');
    const contractsInput = document.getElementById('contract_ids');

    // Ensure that the contract group name is not blank
    if (name?.length <= 0) {
      errorMessage.innerHTML = 'Contract Group Name cannot be blank.';
      btn.disabled = true;
      return false;
    }

    // Ensure that the default allocation percentage is not blank and equals 100%
    const selectedOptions = this.optionTargets.filter((option) =>
      this.select.items.includes(option.innerText)
    );
    const selectedOptionsIds = selectedOptions.map((o) => o.id);
    const percentages = selectedOptions.map((o) => o.dataset.defaultAllocationPercentage);
    const total = percentages.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

    const defaultAllocationPercentage = document.getElementById(
      'total-default-allocation-percentage'
    );

    contractsInput.value = selectedOptionsIds;
    defaultAllocationPercentage.innerHTML = `${total.toFixed(2)}%`;

    // Show error if no contracts are selected
    if (selectedOptions.length === 0) {
      errorMessage.innerHTML = 'Please select at least one contract';
      btn.disabled = true;
      return false;
    }

    // Show error if total exceeds 100%
    if (total > 100.0) {
      errorMessage.innerHTML = 'Total Default Allocation Percentage cannot exceed 100%';
      btn.disabled = true;
      return false;
    }

    // Show error if total is less than 100%
    if (total < 100.0) {
      errorMessage.innerHTML = 'Total Default Allocation Percentage must equal 100%';
      btn.disabled = true;
      return false;
    }

    // SUCCESS FLOW
    if (total === 100.0 && name?.length > 0) {
      btn.disabled = false;
      errorMessage.innerHTML = '';
    }
  }
}
