import { Controller } from '@hotwired/stimulus';

// Should match the values set in delete_codes.rb
const CODES = {
  TEST: '1234',
  DELETECONTRACTCODE: 'ValeDeleteContract',
  DELETEDEALCODE: 'ValeDeleteDealRecord',
  DELETEPOLICYCODE: 'ValeDeletePolicy',
  DELETEADJUSTMENTCODE: 'ValeDeleteAdjustment',
  DELETEADHOCCODE: 'ValeDeleteAdHoc',
  DELETEINVOICECODE: 'ValeDeleteInvoice',
};

export default class extends Controller {
  static targets = ['input', 'submit'];
  static values = {
    confirmationKey: String,
  };

  connect() {
    this.inputTarget.addEventListener('input', this.validate.bind(this));
    this.submitTarget.disabled = true;
  }

  validate() {
    if (['', null, undefined].includes(this.confirmationKeyValue)) {
      return false;
    }

    const confirmationCode = CODES[this.confirmationKeyValue];

    if (this.inputTarget.value === confirmationCode) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }
}
