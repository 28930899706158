import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    searchUrl: String,
  };

  connect() {
    console.log("Hello, Stimulus!", this.dataTarget);
  }

  dataValueChanged() {
    console.log("dataValueChanged", this.dataValue);
  }

  change(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];

    const request = get(`${this.searchUrlValue}/${selectedOption.value}`, {
      responseKind: "turbo-stream",
    });
    request.perform;
  }
}