import { Controller } from "@hotwired/stimulus";
const defaultValue = "nil";

export default class extends Controller {
    static targets = ["select"];
    static values = {
        confirmationMessage: String,
        previousValue: String
    }

    async handleSelect(event) {
        const confirmationMessage = this.confirmationMessageValue;

        if (this.selectTarget.value === defaultValue) {
            this.selectTarget.form.submit();
            return true;
        }

        const confirmed = await this.confirm(confirmationMessage);
        if (confirmed) {
            this.selectTarget.form.submit();
            return true;
        } else {
            event.preventDefault();
            this.selectTarget.value = this.previousValueValue;
            return false;
        }
    }

    confirm(message) {
        const confirmationPopup = document.getElementById("turbo-confirm");
        confirmationPopup.querySelector(".confirmation-text").textContent = message;

        return new Promise((resolve, reject) => {
            confirmationPopup.showModal();
            confirmationPopup.addEventListener("close", () => {
                resolve(confirmationPopup.returnValue === "confirm");
            }, { once: true });
        });
    }
}
