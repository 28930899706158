import { Controller } from '@hotwired/stimulus';
import { CURRENCIES } from '../utils/currency';
import Inputmask from 'inputmask';
export default class extends Controller {
  static targets = ['field', 'currency', 'currencyType', 'hiddenField'];
  static values = { toggleMonetaryUnit: Boolean };

  connect() {
    if (this.toggleMonetaryUnitValue) {
      this.setCurrencyType();
      this.currencyTarget.addEventListener('change', this.setCurrencyType.bind(this));
    }

    this.fieldTargets.forEach((fieldTarget) => {
      Inputmask({
        alias: 'currency',
        radixPoint: '.',
        groupSeparator: ',',
        autoGroup: true,
        digits: 2,
        allowMinus: true,
        inputType: 'number',
        inputmode: 'decimal',
        rightAlign: false,
      }).mask(fieldTarget);

      if (this.hasHiddenFieldTarget) {
        fieldTarget.addEventListener('input', (event) => {
          this.updateHiddenField(event.target.value);
        });
      }
    });
  }

  fieldTargetConnected(_event) {
    this.fieldTargets.forEach((fieldTarget) => {
      Inputmask({
        alias: 'currency',
        radixPoint: '.',
        groupSeparator: ',',
        autoGroup: true,
        digits: 2,
        allowMinus: true,
        inputType: 'number',
        inputmode: 'decimal',
        rightAlign: false,
      }).mask(fieldTarget);

      if (this.hasHiddenFieldTarget) {
        fieldTarget.addEventListener('input', (event) => {
          this.updateHiddenField(event.target.value);
        });
      }
    });
  }

  currencyTypeTargetConnected(_event) {
    if (this.toggleMonetaryUnitValue) {
      this.setCurrencyType();
      this.currencyTarget.addEventListener('change', this.setCurrencyType.bind(this));
    }
  }

  updateHiddenField(value) {
    if (this.hasHiddenFieldTarget) {
      // Hidden Field needed for Filters to work "2,000" with a comma trips Ransack Query -- not the same as "2000" or 2000
      const numericValue = parseFloat(value.replace(/,/g, '')); // Convert to number and remove commas

      // Ensure we are returning a number or null `nil` for :q filters
      if (isNaN(numericValue)) {
        this.hiddenFieldTarget.value = null;
        return;
      }

      this.hiddenFieldTarget.value = numericValue;

      // Manually trigger a change event on the hidden field
      const changeEvent = new Event('change', { bubbles: true });
      this.hiddenFieldTarget.dispatchEvent(changeEvent);
    }
  }

  setCurrencyType(e) {
    const currencyType = CURRENCIES[this.currencyTarget.value];
    let currencySymbol = '';
    if (!currencyType) {
      currencySymbol = '$';
    } else {
      currencySymbol = currencyType.symbol_native;
    }

    this.currencyTypeTargets.forEach((currencyTypeTarget) => {
      currencyTypeTarget.innerHTML = currencySymbol;
    });
  }
}
