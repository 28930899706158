import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tab", "content"];

    showTab(event) {
        const tabId = event.currentTarget.dataset.tabId;

        this.tabTargets.forEach((tab) => {
            if (tabId === tab.dataset.tabId) {
                tab.classList.add("border-blue-500");
                tab.classList.add("text-blue-500");
                tab.classList.remove("text-gray-600");
            }
            if (tabId !== tab.dataset.tabId) {
                tab.classList.remove("border-blue-500");
                tab.classList.remove("text-blue-500");
                tab.classList.add("text-gray-600");
            }
        });
        this.contentTargets.forEach((content) => {
            if (content.dataset.content != tabId) {
                content.classList.add("hidden");
            } else {
                content.classList.remove("hidden");
            }
        });
    }
}
