import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["bindingDate", "form", "lockedRecordModal", "frozenRecordModal"];

    static values = {
        admin: String
    }

    submit(e) {
        e.preventDefault();

        if (this.isFrozen()) { return this.handleFrozenPeriod() };
        if (this.isLocked()) { return this.handleLockedPeriod() };

        this.checkFrozenAndLockedPeriod();
    }

    isAdmin() {
        return this.adminValue === "true";
    }
    
    isFrozen() {
        return this.formTarget.dataset.frozen === 'true';
    }

    isLocked() {
        return this.formTarget.dataset.locked === 'true';
    }

    checkFrozenAndLockedPeriod() {
        const bindingDateTarget = this.bindingDateTarget;

        if (bindingDateTarget) {
            const bindingDate = bindingDateTarget.value.trim();

            if (!bindingDate) {
                this.submitForm();
                return;
            }

            this.fetchFrozenPeriodStatus(bindingDate);
        } else {
            console.error('Binding date target is not present.');
        }
    }

    fetchFrozenPeriodStatus(bindingDate) {
        fetch(`/freeze_periods/check_date?binding_date=${bindingDate}`)
            .then(response => response.json())
            .then(data => {
                if (data.in_frozen_period) {
                    this.handleFrozenPeriod();
                } else if (data.in_lock_period) {
                    this.handleLockedPeriod();
                } else {
                    this.submitForm();
                }
            })
            .catch(error => {
                console.error('Error:', error);
                return true;
            });
    }

    handleFrozenPeriod() {
        if (this.isAdmin()) {
            this.showLockedPeriodModal();
        } else {
            this.showFrozenPeriodModal();
        }
    }

    handleLockedPeriod() {
        this.showLockedPeriodModal();
    }

    showLockedPeriodModal() {
        this.lockedRecordModalTarget.classList.remove('hidden');
    }

    showFrozenPeriodModal() {
        this.frozenRecordModalTarget.classList.remove('hidden');
    }

    closeModal() {
        this.lockedRecordModalTarget.classList.add("hidden");
        this.frozenRecordModalTarget.classList.add("hidden");
    }

    submitForm() {
        this.formTarget.submit();
    }
}
