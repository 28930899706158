import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  toggle() {
    this.contentTargets.forEach((content) => {
      content.classList.toggle('hidden');
    });
  }

  show() {
    this.contentTargets.forEach((content) => {
      content.classList.remove('hidden');
    });
  }

  hide() {
    this.contentTargets.forEach((content) => {
      content.classList.add('hidden');
    });
  }
}
