import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'toggle'];

  connect() {
    this.toggle(); // Call toggle on connect to initialize based on initial checkbox state

    // Attach an event listener to listen for when the button is clicked
    this.toggleTarget.addEventListener('change', this.toggle.bind(this));
  }

  toggle() {
    const useCustomDescription = this.toggleTarget;
    const customDescription = this.contentTarget;

    if (useCustomDescription.checked) {
      customDescription.classList.remove('hidden');
    } else {
      customDescription.classList.add('hidden');
    }
  }
}
