// # frozen_string_literal: true

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['applicableLayerLimit', 'policyLimit', 'submitBtn', 'error'];

  connect() {
    this.applicableLayerLimitTarget.addEventListener('input', this.validate.bind(this));
    this.policyLimitTarget.addEventListener('input', this.validate.bind(this));
    this.validate();
  }

  validate() {
    if (this.applicableLayerLimitTarget.value === '' && this.policyLimitTarget.value === '') {
      // No Validations needed if both fields are empty
      return;
    }

    if (this.applicableLayerLimitTarget.value === '' && this.policyLimitTarget.value !== '') {
      this.submitBtnTarget.disabled = true;
      this.errorTarget.classList.remove('hidden');
      this.errorTarget.innerHTML = '<p>Please enter the Total Applicable Layer Limit.</p>';
      return;
    }

    if (this.policyLimitTarget.value === '' && this.applicableLayerLimitTarget.value !== '') {
      this.submitBtnTarget.disabled = true;
      this.errorTarget.classList.remove('hidden');
      this.errorTarget.innerHTML = '<p>Please enter the VALE Policy Limit.</p>';
      return;
    }
    let policyLimitValue = this.policyLimitTarget.value.replace(/,/g, '');
    let applicableLayerLimitValue = this.applicableLayerLimitTarget.value.replace(/,/g, '');

    // Parse the input values as floats
    let policyLimit = parseFloat(policyLimitValue);
    let applicableLayerLimit = parseFloat(applicableLayerLimitValue);

    const percentage =
      (policyLimit / applicableLayerLimit) * 100;

    if (percentage > 100) {
      this.submitBtnTarget.disabled = true;
      this.errorTarget.classList.remove('hidden');
      this.errorTarget.innerHTML = `
        <p>VALE Limit % will exceed 100%. (${percentage.toFixed(2)}%)</p>
        <p>Please update the VALE Policy Limit or Total Applicable Layer Limit.</p>
      `;
      return;
    } else {
      this.submitBtnTarget.disabled = false;
      this.errorTarget.classList.add('hidden');
      return;
    }
  }
}
