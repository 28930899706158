import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // <%= image_tag('loader.svg', class: "mx-auto hidden") %>
  static targets = ['loader', 'uploadContainer'];

  connect() {
    this.loaderTarget.classList.add('hidden');
  }

  showLoader() {
    this.loaderTarget.classList.remove('hidden');
    this.uploadContainerTarget.classList.add('hidden');
  }

  hideLoader() {
    this.loaderTarget.classList.add('hidden');
  }

  // hide loader on successful form submission
  // action: "turbo:submit-end->loader#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideLoader();
    }
  }

  // show loader when clicking submit button
  // action: "click->loader#showLoader"
  showLoaderOnClick() {
    this.showLoader();
  }
}