import { Controller } from '@hotwired/stimulus';
import { get, post } from '@rails/request.js';

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

export default class InsuredDetailLineItem extends Controller {
  static targets = ['iptRate', 'country'];
  connect() {
    this.countryTarget.addEventListener('change', this.onCountryChange.bind(this));
  }

  countryTargetConnected() {
    this.onCountryChange();
  }

  onCountryChange(event) {
    const country = this.countryTarget.value;

    fetch(`/ipt_rates/rate?country=${country}`, {
      headers: {
        accept: 'application/json',
      },
    }).then((response) => response.json()).then((data) => {
      this.iptRateTarget.innerText = data.tax;
    });
  }
}