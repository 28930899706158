import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "submitButton"];

  toggle(e) {
    if (this.submitButtonTarget.value === e.currentTarget.value) {
      this.removeDisabled();
      this.contentTargets.forEach((content) => {
        content.classList.toggle("hidden");
      });
    } else {
      this.contentTargets.forEach((content) => {
        content.classList.toggle("hidden");
      });
    }
  }

  removeDisabled() {
    const disabledElements = document.querySelectorAll('[disabled]');
    const elementIds = [
      "vale_limit_percent", "policy_excess_attachment_point", "excess_attachment_point", "policy_insurance_premium_tax_amount", "policy_insurance_premium_tax_rate",
        "adjustment_insurance_premium_tax_amount_adjustment", "adjustment_insurance_premium_tax_rate_adjustment"
    ]
    disabledElements.forEach((element) => {      
      if (elementIds.includes(element.id)) {
        element.disabled = true;
      } else {
        element.disabled = false;
      }
    });

    document.dispatchEvent(new CustomEvent("admin_lock:removeDisabled"));
  }
}