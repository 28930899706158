import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
// import debounce from 'lodash/debounce';

const InvoiceTypes = {
  premium: 'premium',
  fee: 'fee',
  other: 'other',
};

// const FeeTypes = {
//   uw_fee: 'UW Fee',
//   pre_exclusivity_uw_fee: 'Pre Exclusivity UW Fee',
//   break_fee: 'Break Fee',
//   excess_markets_total: 'Excess Markets',
// };

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

export default class extends Controller {
  static targets = ['annotation', 'total', 'totalError', 'feeType', 'feeRefTotal', 'removeLineItem'];

  static values = {
    dealId: String,
    invoiceId: String,
    selectedIndex: Number,
    type: String,
    isAdmin: Boolean,
  };

  connect() {
    this.annotationTarget.addEventListener('input', this.updateAnnotation.bind(this));
    this.feeTypeTarget.addEventListener('change', this.feeTypeChanged.bind(this));

    this.totalTarget.addEventListener(
      'input',
      debounce(this.feeTotalChanged.bind(this), 1000).bind(this)
    );
    this.validateTotalInvoiced();
    if (this.isAdminValue) {
      this.createBankIdListener();
      document.getElementById('bank-id').addEventListener('input', this.validateCanSave.bind(this));
    }
    this.validateCanSave();
  }

  feeTypeChanged(event) {
    this.validateTotalInvoiced(event);
    this.validateCanSave();
    this.updatePolicyNumbers(event);
  }

  feeTotalChanged(event) {
    this.validateTotalInvoiced(event);
    this.validateCanSave();
  }

  // DOM way of doing TargetConnected
  createBankIdListener() {
    const bankIdElement = document.getElementById('bank-search-container');
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (_mutationList, _observer) => {
      // We really don't care which "event" is happening here, just that an event occurred to refire validateSave.
      this.validateCanSave();
    };

    const observer = new MutationObserver(callback);

    observer.observe(bankIdElement, config);
  }

  disableSaveButtons(disabled = true) {
    const buttons = document.querySelectorAll(
      '#submit-invoice-btn, #save-invoice-btn, #approve-invoice-btn'
    );
    buttons.forEach((button) => {
      button.disabled = disabled;
    });
  }

  totalTargetConnected(_event) {
    this.totalTarget.addEventListener(
      'input',
      debounce(this.feeTotalChanged.bind(this), 1000).bind(this)
    );
  }

  feeTypeTargetConnected(_event) {
    this.validateTotalInvoiced();
    this.validateCanSave();
  }

  // Built in Dom/Stimulus event
  annotationTargetConnected(_event) {
    if (this.typeValue != InvoiceTypes.other) {
      this.annotationTarget.addEventListener('input', this.updateAnnotation.bind(this));
    }
  }

  totalErrorTargetConnected(_event) {
    this.validateCanSave();
  }

  totalErrorTargetDisconnected(_event) {
    this.validateCanSave();
  }

  // Stimulus Methods
  updateAnnotation(event) {
    const annotationLabel = document.getElementById(
      `line-item-annotation-${this.selectedIndexValue}`
    );

    if (event.target.value.length == 0) {
      annotationLabel.innerHTML = ``;
    } else {
      annotationLabel.innerHTML = `(${event.target.value})`;
    }
  }

  async validateTotalInvoiced(event) {
    let total = '0.00';

    if (this.hasTotalTarget && this.totalTarget.value.length > 0) {
      total = this.totalTarget.value;
    }

    const request = post(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/validate_fee_total_invoiced`,
      {
        responseKind: 'turbo-stream',
        body: {
          index: this.selectedIndexValue,
          total: total,
          fee_type: this.feeTypeTarget.value,
        },
      }
    );

    request.perform;
  }

  validateCanSave() {
    // if there are ANY empty feeTypes, disable the save buttons
    const feeTypeTargets = document.querySelectorAll('[data-fee-line-item-target="feeType"]');
    const hasEmptyFeeTypes = Array.from(feeTypeTargets).some((t) => t.value === '');

    if (hasEmptyFeeTypes) {
      this.disableSaveButtons(true);
      return;
    }

    // Ensure there are no line item errors (totals, etc)
    const totalErrorTargets = document.querySelectorAll('[data-fee-line-item-target="totalError"]');
    const hasTotalErrors = Array.from(totalErrorTargets).some((t) => t.innerHTML.trim().length > 0);

    if (hasTotalErrors) {
      this.disableSaveButtons(true);
      return;
    }

    // Any Totals are empty or 0
    const feeRefTotals = document.querySelectorAll('[data-fee-line-item-target="feeRefTotal"]');
    const hasEmptyRefTotals = Array.from(feeRefTotals).some((t) => t.value === 0);

    if (hasEmptyRefTotals) {
      this.disableSaveButtons(true);
      return;
    }

    // Ensure all totals are filled out
    const totalTargets = document.querySelectorAll('[data-fee-line-item-target="total"]');
    const hasEmptyTotalValue = Array.from(totalTargets).some((t) => t.value === 0);

    if (hasEmptyTotalValue) {
      this.disableSaveButtons(true);
      return;
    }

    // call validateBankingInfo if isAdmin is true
    if (this.isAdminValue) {
      return this.validateBankingInfo();
    } else {
      this.disableSaveButtons(false);
      return;
    }
  }

  removeLineItemTargetDisconnected(_event) {
    this.updatePolicyNumbers();
  }

  updatePolicyNumbers(event) {
    const feeTypeTargets = document.querySelectorAll('[data-fee-line-item-target="feeType"]');
    const uwFeeTypes = Array.from(feeTypeTargets).filter(dropdown => dropdown.value === 'uw_fee');
    const totalUwFeeTypes = uwFeeTypes.length;
    const hasUwFeeType = totalUwFeeTypes > 0;

    const request = post(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/update_policy_numbers`,
      {
        responseKind: 'turbo-stream',
        body: {
          fee_type: hasUwFeeType ? 'uw_fee' : '',
        },
      },
    );

    request.perform;
  }

  validateBankingInfo() {
    // Bank ID is outside of Stimulus controller/target - grab via Dom selector
    const bankId = document.getElementById('bank-id');
    const hasBankingInfo = bankId.value.length > 0;

    if (hasBankingInfo) {
      return this.disableSaveButtons(false);
    } else {
      return this.disableSaveButtons(true);
    }
  }
}
