import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";
export default class extends Controller {
  static targets = ["select", "field"];
  static values = { tax: String, name: String, id: String, country: String, targetElementRateId: String };
  // tax is the tax rate
  // name is the name of the field
  // id is the id of the field
  // country is the Domicile country selected by the user
  // targetElementRateId is the id of the field to update with the tax rate ex: insurance_premium_tax_rate

  initialize() {
    this.onFieldChange();
  }

  connect() {
    Inputmask({
      alias: 'currency',
      radixPoint: '.',
      groupSeparator: ',',
      autoGroup: true,
      digits: 2,
      allowMinus: true,
      inputType: "number",
      inputmode: "decimal",
      rightAlign: false,
    }).mask(this.fieldTarget);
    this.fieldTarget.addEventListener("input", this.detectTyping.bind(this));
  }

  detectTyping(event) {
    if (event.target.name.includes(this.nameValue)) {
      if (this.fieldTarget.value !== this.value) {
        var cursorPosition = event.target.selectionStart;
        this.value = this.fieldTarget.value;
        event.target.setSelectionRange(cursorPosition, cursorPosition);
        this.calculate();
      }
    }
  }

  calculate() {
    let tax = parseFloat(this.taxValue);
    const value = parseFloat(this.value.replace(/,/g, ''));
    const selectedCountry = this.countryValue.length > 0 && document.getElementById(this.countryValue) !== null ? document.getElementById(this.countryValue).value : '';

    if (isNaN(tax) || tax === 0) {
      if (selectedCountry.length === 0) {
        return;
      }
      fetch(`/ipt_rates/rate?country=${selectedCountry}`)
        .then(response => response.json())
        .then(data => {
          tax = data.tax;
          this.updateTaxValue(tax, value);
        })
        .catch(error => {
          console.error("Error fetching tax rate:", error);
        });
    } else {
      this.updateTaxValue(tax, value);
    }
  }

  updateTaxValue(tax, value) {
    const taxValue = tax / 100 * value;
    document.getElementById(this.idValue).value = taxValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Update the tax field and format it with commas

    if (this.targetElementRateIdValue.length > 0) {
      document.getElementById(this.targetElementRateIdValue).value = tax; // Update the tax rate field only if the targetElementRateIdValue is present (endorsement, ad-hoc)
    }
  }

  onCountryChange(event) {
    if (event.target.value.length > 0 && this.fieldTarget.value.length > 0) {
      this.countryValue = event.target.id;
      this.taxValue = '';
      this.onFieldChange();
      this.calculate();
    }
  }

  onFieldChange() {
    this.value = this.fieldTarget.value.replace(/[^0-9.]/g, '');
  }
}