// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import '@fortawesome/fontawesome-free/css/all';

require('@rails/ujs').start();
// require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('product_class');
require('styles/application.scss');
import '@fortawesome/fontawesome-free/js/all';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import '@hotwired/turbo-rails';
import ReactOnRails from 'react-on-rails';

// React Components
import ContractActualsContainer from '../components/ContractActualsContainer';
import DeleteModal from '../components/DeleteModal';
import PolicyContractsManager from '../components/PolicyContractsManager';
import UpdatePageWatcher from '../components/UpdatePageWatcher';

window.Stimulus = Application.start();

ReactOnRails.register({
  ContractActualsContainer,
  PolicyContractsManager,
  // Note: DeleteModal can be removed when PolicyContractsContainer is removed
  DeleteModal,
  UpdatePageWatcher,
});

const context = require.context('../controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.mountComponents();
ReactRailsUJS.useContext(componentRequireContext);
ReactOnRails.setOptions({ turbo: true });
Turbo.session.drive = false;

Turbo.StreamActions.update_input = function () {
  this.targetElements.forEach((target) => {
    target.value = this.templateContent.textContent;
  });
};
