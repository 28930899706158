import { Controller } from "@hotwired/stimulus";

const TOGGLE_VALUES = ["United States", "true"];

export default class extends Controller {
  static targets = ["controlledField", "fieldController"];
  static classes = ["display"];

  connect() {
    this.toggleFrame();
  };

  toggleFrame() {
    const fieldControllerTargetValue = this.fieldControllerTarget.value;

    if (TOGGLE_VALUES.includes(fieldControllerTargetValue)) {
      this.controlledFieldTarget.classList.remove(this.displayClass);
    } else {
      this.controlledFieldTarget.classList.add(this.displayClass);
    }
  };
}