const findByText = (query, text) => {
  const qResult = document.querySelectorAll(query);
  const resultAsArr = Array.from(qResult);

  return resultAsArr.find(el => el.textContent === text);
};

document.addEventListener('turbolinks:load', () => {
  const ele = findByText('button', 'Create New Product');

  const form = document.querySelector('.product-class-form');

  if (ele && form) {
    ele.addEventListener('click', () => {
      ele.classList.add('hide');
      form.classList.remove('hide');
    });

    const cancelBtn = findByText('button', 'Cancel');

    cancelBtn.addEventListener('click', () => {
      form.classList.add('hide');
      ele.classList.remove('hide');
    });
  }
});
