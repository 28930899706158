import Inputmask from "inputmask";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["percentField"];
  // This is if we want to add custom values to the controller down the line.
  // static values = { decimalPlaces: Number, allowMinus: Boolean, rightAlign: Boolean, groupSeparator: String, radixPoint: String };

  connect() {
    new Inputmask({
      alias: 'currency',
      radixPoint: '.',
      groupSeparator: '',
      autoGroup: false,
      digits: 8,  // This is custom for VALE percentage percentFields.  It allows up to 8 decimal places.
      allowMinus: true,
      inputType: 'number',
      inputmode: 'decimal',
      rightAlign: false,
      suffix: '%',
    }).mask(this.percentFieldTarget);

  }
}