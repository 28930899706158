import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const InvoiceTypes = {
  premium: 'premium',
  fee: 'fee',
  other: 'other',
};

export default class extends Controller {
  static targets = ['invoiceType', 'customDescriptionInput', 'customDescription', 'dueDate'];
  static values = {
    dealId: String,
    invoiceId: String,
  };

  connect() {
    this.dueDateTarget.addEventListener('change', this.changeDueDate.bind(this));
    const customDescription = this.targets.find('customDescriptionInput');

    if (customDescription !== undefined) {
      this.customDescriptionInputTarget.addEventListener(
        'input',
        this.updateCustomDescriptions.bind(this)
      );
    }
  }

  dueDateTargetConnected(_event) {
    if (this.hasDueDateTarget) {
      this.dueDateTarget.addEventListener('change', this.changeDueDate.bind(this));
    }
  }

  changeDueDate(event) {
    const ninety_out = dayjs.utc().add(90, 'day');
    const due_date = dayjs.utc(event.target.value);
    const is_past_ninety = due_date.isAfter(ninety_out);

    if (is_past_ninety) {
      const request = get(
        `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/change_due_date_warning`,
        {
          responseKind: 'turbo-stream',
        }
      );

      request.perform;
    }
  }

  customDescriptionInputTargetConnected(_event) {
    this.customDescriptionInputTarget.addEventListener(
      'input',
      this.updateCustomDescriptions.bind(this)
    );
  }

  changeAddressType(event) {
    const selectedAddressType = event.target.value;
    if (selectedAddressType.length === 0) {
      return;
    }

    const request = get(
      `/deals/${this.dealIdValue}/invoices/${this.invoiceIdValue}/change_address_type/${selectedAddressType}`,
      {
        responseKind: 'turbo-stream',
      }
    );

    request.perform;
  }

  // TODO: When New Line Items enter -- This should be revalidated to pass description down to new fields
  // Possibly when customDescriptionTargetConnected is called?
  // Maps Fee/Other description field to all line items (hidden fields) for saving
  updateCustomDescriptions(event) {
    const customDescriptionElements = this.customDescriptionTargets;
    customDescriptionElements.forEach((element) => {
      element.innerHTML = event.target.value;
      element.value = event.target.value;
    });
  }
}
