import { Controller } from '@hotwired/stimulus';

// TODO: Rename to DEAL Brokerage Controller
export default class extends Controller {
  static values = {
    brokerageId: String,
  };

  connect() {
    document.addEventListener('autocomplete.change', (event) => {
      const isBrokerageContained =
        event.target.closest("[data-controller='autocomplete brokerage']") !== null;

      if (event.detail.value && isBrokerageContained) {
        this.brokerageIdValue = event.detail.value;
        this.changeBrokerage();
      }
    });
  }

  // Built-in Stimulus lifecycle method
  brokerageIdValueChanged(e) {
    if (this.brokerageIdValue.length > 0) {
      document.querySelector('#broker-container').classList.remove('hidden');
    }
  }

  // action: "change->turbo-modal#changeBrokerage"
  changeBrokerage() {
    let brokerage = this.brokerageIdValue;
    let url = `/brokerages/${brokerage}/brokers`;
    let createNewLink = document.getElementById('create_new_link');

    if (createNewLink.href.includes('brokerage_id')) {
      createNewLink.href = createNewLink.href.replace(
        /brokerage_id=[^&]+/,
        'brokerage_id=' + encodeURIComponent(brokerage)
      );
    } else {
      createNewLink.href += '?brokerage_id=' + encodeURIComponent(brokerage);
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let brokerSelect = document.querySelector('#deal_broker_id');
        document.querySelector('#broker-container').classList.remove('hidden');

        brokerSelect.innerHTML = '';
        let blankOption = document.createElement('option');
        blankOption.value = ''; // Set the value to an empty string or another default value
        blankOption.innerText = ''; // Set the text for the blank option
        brokerSelect.appendChild(blankOption);

        data.brokers.forEach((broker) => {
          let option = document.createElement('option');
          option.value = broker.id;
          option.innerText = broker.name;
          brokerSelect.appendChild(option);
        });
      });
  }
}
