import { Controller } from '@hotwired/stimulus';

const debounce = (fn, delay = 10) => {
  let timeoutId = null;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};

export default class InsuredDetails extends Controller {
  static targets = ['addInsuredDetail', 'allocationPercentage'];

  connect() {
    this.addInsuredDetailTarget.addEventListener('click', this.addInsuredDetail.bind(this));
    this.addAllocationPercentageTotal();
  }

  allocationPercentageTargetConnected() {
    this.validateCanSave();
    this.debouncedCheckAllocationPercentages = debounce(this.checkAllocationPercentages, 1000);
    this.allocationPercentageTargets.forEach((el) => {
      el.addEventListener('input', this.debouncedCheckAllocationPercentages.bind(this));
    });
  }

  validateCanSave() {
    //   If there are ANY empty allocation percentages, disable the submit button
    const allocationPercentages = document.querySelectorAll('.allocation-percentage');
    const hasEmpty = Array.from(allocationPercentages).some((el) => el.value === '');

    if (hasEmpty && allocationPercentages.length > 1) {
      document.getElementById('submit-insured-detail-btn').disabled = true;
      document.getElementById('allocation-percentage-error-message').classList.remove('hidden')
      this.addAllocationPercentageTotal();
    }
  }

  addInsuredDetail() {
    document.getElementById('allocation-percentage-0').classList.remove('hidden');
  }

  addAllocationPercentageTotal() {
    const allocationPercentages = document.querySelectorAll('.allocation-percentage');
    const total = Array.from(allocationPercentages).reduce((acc, el) => {
      return acc + (el.value ? parseFloat(el.value) : 0);
    }, 0);
    const allocatedPercentageTotal = document.getElementById('allocated-percentage-total');

    if (allocationPercentages.length > 1) {
      allocatedPercentageTotal.classList.remove('hidden');
    }
    allocatedPercentageTotal.innerText = `Allocated Percentage Total ${total}%`;
  }

  checkAllocationPercentages(event) {
    // Debounce the function to prevent it from running too frequently
    const allocationPercentages = document.querySelectorAll('.allocation-percentage');
    const total = Array.from(allocationPercentages).reduce((acc, el) => {
      return acc + (el.value ? parseFloat(el.value) : 0);
    }, 0);
    const submitBtn = document.getElementById('submit-insured-detail-btn');
    const allocationPercentageErrorMessage = document.getElementById('allocation-percentage-error-message');
    const hiddenAllocationPercentage = document.getElementById('allocation-percentage-0');
    const allocatedPercentageTotal = document.getElementById('allocated-percentage-total');

    if (allocationPercentages.length > 1) {
      hiddenAllocationPercentage.classList.remove('hidden');
      total !== 100 ? allocationPercentageErrorMessage.classList.remove('hidden') : allocationPercentageErrorMessage.classList.add('hidden');
      allocatedPercentageTotal.classList.remove('hidden');
      allocatedPercentageTotal.innerText = `Allocated Percentage Total ${total}%`;
      submitBtn.disabled = total !== 100;
    } else {
      allocationPercentageErrorMessage.classList.add('hidden');
      hiddenAllocationPercentage.classList.add('hidden');
      allocatedPercentageTotal.innerText = '';
      allocatedPercentageTotal.classList.add('hidden');
    }
  }
}