import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="display"
export default class extends Controller {
  // connect() {
  // }
  toggle_declined_reason(event) {
    event.preventDefault();
    const declinedReason = document.getElementById("declined_reason");

    if (event.target.value == "true") {
      declinedReason.style.display = "block";
    } else {
      declinedReason.style.display = "none";
    }
  }
}
